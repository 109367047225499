import Template from "./template"
import YourPage from "./newDevPageTemplate/main"
import { Routes, Route, Link } from "react-router-dom"
import HomePage from "./pages/home"
import NavBar from "./NavigationBar"

function App() {
  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="template-page" element={<YourPage />} />
      </Routes>
    </div>
  )
}

export default App
