import logo from "../logo.svg"
import "../App.css"

function HomePage() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
      <div>
        <h1>KC Dev Pages</h1>

        <h3>A simple project to get you started.</h3>
        <p>
          There is nothing fancy going on here. Just a create-react-app that is
          meant to help you figure out a few basics.
        </p>
        <ol className="Basics-list">
          <li>How to clone an existing repo in GitHub</li>
          <li>
            How to create a feature branch to contribute to this open source
            project
          </li>
          <li>Give you real life experience</li>
        </ol>
      </div>
      <div className="Why-section">
        <h2>Why?</h2>
        <h3>
          Sometimes there is just too much to learn from the start. You do not
          need to understand how to host a website or how to make your site
          secure with an SSL certificate. These were roadblocks for me when I
          started. This is a simple site hosted for you, that provides you with
          the bare necessities to start coding and gives you the abilitiy to
          provide a link to a live website where you can show what you have
          built.
        </h3>
        <div className="Boxed-text">
          <h4>
            This page is meant to be no frills so you can easily read and
            understand the code. Copy it and reuse it. There are a few
            classNames like "Basics-list", "Why-section", and this "Boxed-text"
            that you can find in the App.css file that can help you style your
            page as well.
          </h4>
        </div>
      </div>
    </div>
  )
}
export default HomePage
