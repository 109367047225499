import React from "react"
import { Link } from "react-router-dom"
function Navbar() {
  return (
    <div className="Navigation-bar-container">
      <div className="Nav-link-button">
        <Link to="/" id="home" className="Nav-link">
          Home
        </Link>
      </div>
      <div className="Nav-link-button">
        <Link to="/template-page" id="template-page" className="Nav-link">
          Your Page
        </Link>
      </div>
    </div>
  )
}
export default Navbar
